import {Component, createRef} from 'react'
import { connect } from 'react-redux'
import { addAlbumImageTmpUrlReload, addAlbumImageTmpUrl } from '../actionTypes/actionTypes'
import config from '../config'
// const awaitImage = wrappedComponent =

import {isMobile} from '../utilityFunctions'


class AlbumImage extends Component {
  constructor(props) {
    super(props)
    const {albumData} = props
    this.state = {
      'imageUrl': null,
      'sizeName': albumData['thumbnail_size']
    }
    this.setSizeName(true)
    this.mobileTimeout = null;
    this.mobileTapStartTime = null;
    this.displayLargerImage = this.displayLargerImage.bind(this)
    this.downloadImage = this.downloadImage.bind(this)
    this.mobileTapStartScrollY = null
    this.screenSizeOnImageLoad = null
    this.checkboxClick = this.checkboxClick.bind(this)
    this.imageContainer = createRef();
    this.imageLoadLock = false;
  }

  async displayLargerImage(e) {
    e.preventDefault()
    const {imageData} = this.props
    if (isMobile()) {
      // if (this.mobileTimeout) {
       // window.clearTimeout(this.mobileTimeout)
       // this.mobileTimeout = window.setTimeout(this.openMobile.bind(this), 200)
      // }
      // else {
        // this.mobileTimeout = window.setTimeout(this.toggleSelection.bind(this), 200);
      // }
    }
    else if (e.ctrlKey) {
      this.toggleSelection()
    }
    else {
      this.props.enlargeImage({...imageData})
    }
  }

  storeStartTime(e) {
    this.mobileTapStartTime = null;
    this.mobileTapStartScrollY = null
    if (isMobile()) {
      this.mobileTapStartScrollY = window.scrollY
      this.mobileTapStartTime = (new Date()).getTime();
    }
  }

  async handleTapResponse(e) {
    if (isMobile() && this.mobileTapStartTime  && this.mobileTapStartScrollY == window.scrollY) {
      const tapDuration = (new Date()).getTime() - this.mobileTapStartTime;
      if (tapDuration > 200) {
        this.toggleSelection()
      }
      else {
        this.openMobile()
      }
    }
    this.mobileTapStartTime = null;
  }

  openMobile() {
    const {imageData} = this.props
    window.setTimeout(() => {
      this.props.enlargeImage({...imageData})
    }, 100);
    this.mobileTimeout = null
  }

  toggleSelection() {
    const {imageData} = this.props
    if (this.props.isSelected) {
      this.props.removeFromSelection(imageData['filename'])
    }
    else  {
      this.props.addToSelection(imageData['filename'])
    }
    this.mobileTimeout = null
  }

  componentDidUpdate(prevProps) {

    // console.log(this.props['maxWindowScroll'] != prevProps['maxWindowScroll'])
    // console.log(this.props['maxWindowScroll'])
    let scrollCheck = true;
    let inWindow = false
    // console.log('-----componentDidUpdate')
    // console.log(this.props['imageData']['filename'])
    if (this.imageContainer.current) {
      const placeholderOffsetTop = this.imageContainer.current.offsetTop
      inWindow = placeholderOffsetTop <= this.props['maxWindowScroll']
      scrollCheck = this.props['maxWindowScroll'] != prevProps['maxWindowScroll'] && inWindow && prevProps['maxWindowScroll'] < placeholderOffsetTop
      // console.log(placeholderOffsetTop)
      // console.log()

      // console.log(scrollCheck)
      // console.log(inWindow)
      // console.log(this.props['maxWindowScroll'] != prevProps['maxWindowScroll'])
      // console.log(prevProps['maxWindowScroll'] < placeholderOffsetTop)
      // console.log(prevProps['maxWindowScroll'])
      // console.log(placeholderOffsetTop)
    }
    // console.log('-----componentDidUpdate-')
    // console.log(this.imageContainer.current)
    // console.log(this.props['loadImageState'])
    // console.log(prevProps['loadImageState'])
    // console.log(inWindow)

    if ((this.props['loadImageState'] > prevProps['loadImageState']
            || scrollCheck
            || (this.props['loadImageState'] > 0 && inWindow && this.state['imageUrl'] === null))
        && this.props['loadImageState'] > 0
        && inWindow) {
      // console.log('-----')
      // console.log(this.props['imageData']['filename'])
      // console.log(prevProps['loadImageState'])
      // console.log(this.props['loadImageState'])
      // console.log('--')
      // console.log(this.props['loadImageState'] > prevProps['loadImageState'])
      // console.log(scrollCheck)
      // console.log(this.props['loadImageState'] > 0)
      // console.log(inWindow)
      this.setSizeName()
      this.loadImage()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Rendering the component only if
    // passed props value is changed
    // let positio
    let scrollCheck = true;
    let scrollProgress = false;
    if (this.imageContainer.current) {
      const placeholderOffsetTop = this.imageContainer.current.offsetTop
      // console.log(placeholderOffsetTop)
      // console.log(this.props['maxWindowScroll'])
      // console.log(nextProps['maxWindowScroll'])
      // console.log('maxwindowScroll-')
      // console.log(this.screenSizeOnImageLoad)
      // if nextProps maxwindowScroll is less than current its because of a browser resize
      scrollCheck = placeholderOffsetTop < nextProps['maxWindowScroll']
      scrollProgress = (nextProps['loadImageState'] > 0
                        && this.props['maxWindowScroll'] != nextProps['maxWindowScroll']
                        && this.props['maxWindowScroll'] <= placeholderOffsetTop
                        && scrollCheck)

      // this.props['maxWindowScroll'] != nextProps['maxWindowScroll']

      // || this.state['imageUrl'] == null

                      // && (this.props['maxWindowScroll'] <= placeholderOffsetTop || window.innerHeight < this.screenSizeOnImageLoad))
                     // ;
    }
    // console.log('---shouldComponentUpdate')
    // console.log(nextProps['imageData']['filename'])
    // console.log(this.props['loadImageState'])
    // console.log(nextProps['loadImageState'])
    // console.log(scrollCheck)
    // console.log(scrollProgress)
    // console.log(this.state['imageUrl'])
    // console.log(nextState['imageUrl'])
    if ((nextProps['loadImageState'] > this.props['loadImageState'] && scrollCheck)
        || (this.props['loadImageState'] > 0 && scrollCheck && this.state['imageUrl'] == null)
        || scrollProgress
        || nextProps['isSelected'] !== this.props['isSelected']
        || nextState['imageUrl'] != this.state['imageUrl']
        ) {
        // console.log(nextProps['imageData']['filename'] + '---shouldComponentUpdate -- did update')

                        // key={imageData['filename']}
              // loadImageState={this.imageThumbnailStates[idx]}
              // maxWindowScroll={maxWindowScroll}
              // imageData={imageData}
              // albumData={albumData}
              // loadNextThumbnail={this.loadNextThumbnail}
              // isSelected={selection.indexOf(imageData['filename']) > -1}
              // addToSelection={this.addToSelection}
              // removeFromSelection={this.removeFromSelection}
              // enlargeImage={this.displayLargeImage}
              // downloadImage={this.downloadImage}
      return true;
    } else {
      // console.log(nextProps['imageData']['filename'] + '---shouldComponentUpdate -- did not update')

      return false;
    }
  }

  downloadImage(e) {
    e.preventDefault()
    e.stopPropagation()
    const {imageData, downloadImage} = this.props
    downloadImage(imageData['filename'])
  }

  setSizeName(onInit) {
    const {albumData, imageData, loadImageState, cachedUrls} = this.props
    const filmStripSizeName = albumData['filmstrip_size']

    if (filmStripSizeName && imageData['sizes'][filmStripSizeName]) {
      const filmStripPath = albumData['pagename'] + '/' + imageData['sizes'][filmStripSizeName]['size_filename']

      if (isMobile() || loadImageState > 1 || cachedUrls[filmStripPath]) {
        this.state['sizeName'] = albumData['filmstrip_size']
      }
      if (cachedUrls[filmStripPath] && onInit) {
        this.state['imageUrl'] = cachedUrls[filmStripPath]
      }
    }
    else if (onInit) {
      const thumbnailPath = albumData['pagename'] + '/' + imageData['sizes'][filmStripSizeName]['size_filename']
      if (cachedUrls[thumbnailPath]) {
        this.state['imageUrl'] = cachedUrls[thumbnailPath]
      }
    }
  }

  checkboxClick(e) {
    this.toggleSelection()
  }

  loadImage() {

    const {albumData, imageData, loadImage, cachedUrls, albumKey} = this.props
    const {sizeName} = this.state

    let albumImagePath = albumData['pagename'] + '/' + imageData['sizes'][sizeName]['size_filename']
/*    if (sizeName && imageData['sizes'][sizeName]) {

      const filmStripPath = albumData['pagename'] + '/' + imageData['sizes'][sizeName]['size_filename']
      if (isMobile() || cachedUrls[filmStripPath]) {
        albumImagePath = filmStripPath
      }
    }*/
    if (cachedUrls[albumImagePath]) {
      if (this.state.imageUrl == cachedUrls[albumImagePath]) {
        this.props.loadNextThumbnail()
      }
      else {
        // console.log('already loaded - ' + albumImagePath)
        this.screenSizeOnImageLoad = window.innerHeight

        window.setTimeout(() => {
          this.setState({
            imageUrl: cachedUrls[albumImagePath]
          })
        }, Math.random() * 100);

      }
    }
    else {
      const myHeaders = new Headers({
        Authorization: 'Bearer ' + albumKey,
      })
      const apiurl = config['pyalbum_host'] + '/img/' + albumImagePath
      if (this.imageLoadLock === false) {
        this.imageLoadLock = true
        fetch(apiurl, {
          headers: myHeaders
        })
        .then((response) => {
          if (response.ok) {
            response.blob().then((responseContent) => {
              const imageObjectUrl = URL.createObjectURL(responseContent)
              this.setState({
                imageUrl: imageObjectUrl
              })
              this.screenSizeOnImageLoad = window.innerHeight
              this.props.addAlbumImageTmpUrl(albumImagePath, imageObjectUrl)
              this.imageLoadLock = false
            })
          }
          else {
            window.setTimeout(() => {
              fetch(apiurl, {
                headers: myHeaders
              })
              .then((response) => {
                if (response.ok) {
                  response.blob().then((responseContent) => {
                    const imageObjectUrl = URL.createObjectURL(responseContent)
                    this.setState({
                      imageUrl: imageObjectUrl
                    })
                    this.screenSizeOnImageLoad = window.innerHeight
                    this.props.addAlbumImageTmpUrl(albumImagePath, imageObjectUrl)
                    this.imageLoadLock = false
                  })
                }
                else {

                }

              })
            }, 1000)


          }
        })

        // try {
          // const response = await f
          // const imageBlob = await response.blob()
          // const imageObjectUrl = URL.createObjectURL(imageBlob)
          // this.setState({
            // imageUrl: imageObjectUrl
          // })
          // this.screenSizeOnImageLoad = window.innerHeight
          // this.props.addAlbumImageTmpUrl(albumImagePath, imageObjectUrl)
        // }
        // catch(e) {
          // console.log(e)
          // window.setTimeout(() => {
            // const response = fetch(apiurl, {
              // headers: myHeaders
            // })
            // .then((response) => response.blob())
            // .then((responseContent) => {
            // const imageObjectUrl = URL.createObjectURL(responseContent)
            // this.setState({
              // imageUrl: imageObjectUrl
            // })
            // this.screenSizeOnImageLoad = window.innerHeight
            // this.props.addAlbumImageTmpUrl(albumImagePath, imageObjectUrl)
            // })

          // }, 1000)
        // }
      }
    }
  }

  componentDidMount() {
    const {loadImageState} = this.props
    if (this.imageContainer.current) {
      // console.log(this.imageContainer.current.offsetTop);

    }
    // console.log('componentDidMount-----')
    // console.log(this.props['imageData']['filename'])
    // console.log(this.props['loadImageState'])
    if (loadImageState > 0) {
      this.loadImage()
    }
  }

  render() {
    const {albumData, imageData, isSelected} = this.props
    const {sizeName, imageUrl} = this.state
    // console.log(imageData['filename'] + '-rendered')
    let imageSize = {
      width: 200,
      height: 200
    }
    if (imageData['sizes'][sizeName]) {
      imageSize = imageData['sizes'][sizeName]
    }

    let returnStr = (
      <div className="image-thumbnail" ref={this.imageContainer}>
        <img
          src=""
          width={imageSize['width']}
          height={imageSize['height']}
        />
        <div>{imageData['title']}</div>
      </div>
    )
    const classNames = ['image-thumbnail']
    if (isSelected) {
      classNames.push('selected-image')
    }
    if (imageUrl) {
      // console.log('activate - ' + imageData['title'])

      returnStr = (
        <div className={classNames.join(' ')} ref={this.imageContainer}>
          <div className="image-checkbox-container">
            <input type="checkbox"
              onChange={() => {}}
              onClick={this.checkboxClick}
              checked={isSelected}
              // defaultChecked={isSelected}
              />
          </div>
          <div className="thumbnail-image-container">
            <div className="image-title" onClick={this.displayLargerImage}>{imageData['title']}</div>
            <img
              src={imageUrl}
              width={imageSize['width']}
              height={imageSize['height']}
              onLoad={this.props.loadNextThumbnail}
              onClick={this.displayLargerImage}
              onTouchStart={this.storeStartTime.bind(this)}
              onTouchEnd={this.handleTapResponse.bind(this)}
            />
          </div>
          <div className="mobile-only"><a href="#" onClick={this.downloadImage}>Download Image</a></div>
        </div>
      )
    }
    return returnStr
  }
}

const mapStateToProps = (state, ownProps) => {
  const {albumData, imageData} = ownProps
  const thumbnailPath =  albumData['pagename'] + '/' + imageData['sizes'][albumData['thumbnail_size']]['size_filename']
  const cachedUrls = {}
  cachedUrls[thumbnailPath] = state.albumReducer.image_temp_url_cache[thumbnailPath]

  if (albumData['filmstrip_size'] && imageData['sizes'][albumData['filmstrip_size']]) {
    const filmStripPath =  albumData['pagename'] + '/' + imageData['sizes'][albumData['filmstrip_size']]['size_filename']
    cachedUrls[filmStripPath] = state.albumReducer.image_temp_url_cache[filmStripPath]
  }
  return {
    albumKey: state.albumReducer.album_key,
    cachedUrls
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlbumImageTmpUrlReload: (albumImagePath, filename) => dispatch(addAlbumImageTmpUrlReload(albumImagePath, filename)),
    addAlbumImageTmpUrl: (albumImagePath, filename) => dispatch(addAlbumImageTmpUrl(albumImagePath, filename))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AlbumImage)