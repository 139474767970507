import {Component} from 'react'
import {connect} from 'react-redux'
import config from '../config'

import moment from 'moment'

class AlbumSlideShowLargeImagePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.saveFeaturedImage = this.saveFeaturedImage.bind(this)
    this.downloadImage = this.downloadImage.bind(this)
    this.deleteImages = this.deleteImages.bind(this)

  }

  saveFeaturedImage() {
    const {albumData, imageData, albumKey} = this.props
    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
      'Content-type': 'application/json'
    })
    const postData = {
      'featured_image': imageData['filename']
    }
    fetch(config['pyalbum_host'] + '/albums/' + albumData['pagename'], {
      headers: myHeaders,
      method: 'PUT',
      body: JSON.stringify(postData)
    })
    .then(response => response.json())
    .then(data => {
    })
  }

  downloadImage() {
    const {imageData} = this.props
    this.props.downloadImage(imageData['filename'])
  }

  deleteImages(){
    const {deleteImages, imageData} = this.props
    deleteImages([imageData['filename']])
  }

  renderDisplayImageMeta(metaKeyValue) {
    const [key, value] = metaKeyValue
    return <div key={key}><span>{key}</span>: <span>{value}</span></div>
  }

  renderSelectionBtn() {
    const {imageData} = this.props
    let btnSelection = <input
      type="button"
      className="btn"
      onClick={(e) => this.props.addToSelection(imageData['filename'])}
      value="Select Image"
      />
    if (this.props.selection.indexOf(imageData['filename']) > -1) {
      btnSelection = <input
      type="button"
      className="btn"
      onClick={(e) => this.props.removeFromSelection(imageData['filename'])}
      value="Unselect Image"
      />
    }
    return btnSelection
  }

  render() {
    const {imageData, albumData} = this.props
    if (!imageData) {
      return <div />
    }

    let editLinks = <></>
    if (config['userRolesOrder'].indexOf(albumData['current_user_role']) > 1) {
      editLinks = (
        <>
          <input type="button" className="btn" onClick={this.saveFeaturedImage} value="Make Featured Image" />
          <input type="button" className="btn" onClick={this.deleteImages} value="Delete Image" />
        </>
      )
    }

    const displayImageMeta = {}
    const {exif_data} = imageData
    if (exif_data && exif_data.DateTimeOriginal) {
      const originalMoment = moment(exif_data.DateTimeOriginal, 'YYYY:MM:DD hh:mm:ss');
      displayImageMeta['Timestamp Taken'] = originalMoment.format('M/D/YYYY hh:mma')
    }
    const btnSelection = this.renderSelectionBtn()
    return (
      <div className='large-image-panel' onClick={(e) => e.stopPropagation()}>
        <div className="image-panel-top">
          <span className='image-filename'>{imageData['filename']}</span>
          {btnSelection}
          <input type="button" className="btn" onClick={this.downloadImage} value="Download Image" />
          {editLinks}
        </div>
        <div className='large-image-meta'>
          {Object.entries(displayImageMeta).map(this.renderDisplayImageMeta)}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    albumKey: state.albumReducer.album_key
  }
}
export default connect(mapStateToProps, null)(AlbumSlideShowLargeImagePanel)