import { combineReducers } from 'redux'

const galleryInitialState = {
  gallery: []
}

const galleryReducer = (state = galleryInitialState, action) => {
  switch(action.type) {
    case "add_albums":
      state['gallery'] = action.galleryData
      return state
    default:
      return state
  }
}

const albumsInitialState = {
  'album_key': null,
  'albums': {},
  'image_temp_url_cache': {}
}
const albumReducer = (state = albumsInitialState, action) => {
  switch(action.type) {
    case 'upsert_album':

      state['albums'][action.albumData.pagename] = action.albumData
      return {
        ...state
      }
    case 'add_image_temp_url':
      state['image_temp_url_cache'][action.albumImagePath] = action.imageTmpUrl
      return state
    case 'add_image_temp_url_reload':
      const image_temp_url_cache = {
        ...state.image_temp_url_cache
      }

      image_temp_url_cache[action.albumImagePath] = action.imageTmpUrl
      return {
        ...state,
        image_temp_url_cache
      }
    case 'set_album_key':
      const newState = albumsInitialState;
      newState['album_key'] = action.albumKey
      return newState
    default:
      return state
  }
}

export default combineReducers({
  galleryReducer,
  albumReducer
});