import {Component} from 'react'
import {Link} from 'react-router-dom'
import {withRoute} from './withRoute'
import { connect } from 'react-redux'
import {upsertAlbumData} from '../actionTypes/actionTypes'

import config from '../config'


class EditAlbumForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userCount: 1,
      albumSizesCount: 1,
      albumDataLoaded: false
    }
    this.formData = {
      title: '',
      author: '',
      pagename: '',
      date_taken: '',
      users: [],
      sizes: [],
      featured_image_size: null,
      thumbnail_size: null,
      filmstrip_size: null,
      large_size: null,
      download_size: null,
      album_text: ''
    }
    this.albumData = null
    this.gallerySizes = []
    this.handleChange = this.handleChange.bind(this)
    this.addUser = this.addUser.bind(this)
    this.addSize = this.addSize.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  handleChange(e) {
    const formData = this.formData
    const userIdx = e.target.getAttribute('userindex');
    const sizeIdx = e.target.getAttribute('sizeindex');
    if (userIdx) {

      const users = this.formData['users']
      if (!users[userIdx]) {
        users[userIdx] = {
          'username': '',
          'email_address': ''
        }
      }
      users[userIdx][e.target.name] = e.target.value
      this.formData['users'] = users
      // this.setState({
        // 'users': users
      // })
    }
    else if (sizeIdx) {

      const sizes = this.formData['sizes']
      if (!sizes[sizeIdx]) {
        sizes[sizeIdx] = {
          'size_name': '',
          'width': 500,
          'height': 500,
          'watermark': false
        }
      }
      let value = e.target.value
      if (e.target.type == 'number') {
        value = Number(value)
      }
      else if (e.target.type == 'checkbox') {
        value = e.target.checked
      }

      sizes[sizeIdx][e.target.name] = value
      if (e.target.name == 'size_name') {
        this.setState({})
      }
      this.formData['sizes'] = sizes
    }
    else {
      // console.log(e.target.value)
      // const updateForm = {}
      // updateForm[e.target.name] = e.target.value
      this.formData[e.target.name] = e.target.value
      // console.log(this.formData)
      // this.setState(updateForm)
    }

    // console.log(e)
  }

  addUser(e) {
    const {userCount} = this.state
    this.setState({
      userCount: userCount + 1
    })
  }

  addSize(e) {
    const {albumSizesCount} = this.state
    this.setState({
      albumSizesCount: albumSizesCount + 1
    })
  }

  submitForm(e) {
    e.preventDefault();
    const {albumPagename} = this.props.params
    const {albumKey, galleryData} = this.props

    let apiUrl = config['pyalbum_host'] + '/gallery/albums'
    let method = 'POST'
    const postData = {
      title: this.formData['title'],
      author: this.formData['author'],
      pagename: this.formData['pagename'],
      date_taken: this.formData['date_taken'],
      featured_image_size: this.formData['featured_image_size'],
      thumbnail_size: this.formData['thumbnail_size'],
      filmstrip_size: this.formData['filmstrip_size'],
      large_size: this.formData['large_size'],
      download_size: this.formData['download_size'] ? this.formData['download_size'] : null,
      album_text: this.formData['album_text']
    }

    const gallerySizeNames = this.gallerySizes.map(size => size['size_name'])
    postData['sizes'] = this.formData['sizes'].filter(size => gallerySizeNames.indexOf(size['size_name']) == -1)
    // delet
    if (albumPagename) {
      apiUrl = config['pyalbum_host'] + '/albums/' + albumPagename
      method = 'PUT'
    }
    else {
      postData['users'] = this.formData['users']
    }

    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
      'Content-type': 'application/json'
    })

    fetch(apiUrl, {
      'method': method,
      'body': JSON.stringify(postData),
      'headers': myHeaders
    })
    .then(response => response.json())
    .then(albumData => {
      if (albumPagename) {
        if (galleryData.length > 0) {
          galleryData.forEach((album) => {
            if (album['albumid'] == albumData['albumid']) {
              Object.assign(album, albumData)
              this.props.upsertAlbumData(albumData)
            }
          })
        }
        else {
          Object.assign(this.albumData, albumData)
          this.props.upsertAlbumData(this.albumData)
        }
      }
      else {
        galleryData.push(albumData)
      }
    })
  }

  componentDidMount() {
    const {albumPagename} = this.props.params
    const {albumKey} = this.props

    const myHeaders = new Headers({
      Authorization: 'Bearer ' + albumKey,
    })
    fetch(config['pyalbum_host'] + '/gallery/sizes', {
      'headers': myHeaders
    })
    .then(response => response.json())
    .then(gallerySizes => {
      this.gallerySizes = gallerySizes
      if (this.albumData === null) {
        this.formData['sizes'] = [...gallerySizes]
        this.setState({
          albumSizesCount: gallerySizes.length + 1
        })
      }
    })

    if (albumPagename) {
      fetch(config['pyalbum_host'] + '/albums/' + albumPagename, {
        'headers': myHeaders
      })
      .then(response => response.json())
      .then(albumData => {
        this.albumData = albumData
        this.formData = {
          'title': albumData['title'],
          'author': albumData['author'],
          'pagename': albumData['pagename'],
          'date_taken': albumData['date_taken'],
          'users': albumData['users'],
          'sizes': albumData['sizes'],
          'album_text': albumData['album_text'],
          featured_image_size: albumData['featured_image_size'],
          thumbnail_size: albumData['thumbnail_size'],
          filmstrip_size: albumData['filmstrip_size'],
          large_size: albumData['large_size']
        }

        this.setState({
          albumDataLoaded: true,
          userCount: albumData['users'].length,
          albumSizesCount: albumData['sizes'].length + 1
        })
      })
    }
  }

  renderUserSection() {
    const { userCount } = this.state
    const users = Array(userCount).fill(0).map((t, userIdx) => {
      return this.renderUserRow(userIdx)
    })
    let btnContainer = <></>
    if (this.albumData === null) {
      btnContainer = (
        <div className="btn-container">
         <input type="button" className="btn btn-submit" onClick={this.addUser} defaultValue="Add User" />
        </div>
      )
    }

    return (
      <div>
        <h2 className="frm-section-header">Album Users:</h2>
        {users}
        {btnContainer}
      </div>
    )
  }

  renderUserRow(userIdx) {
    const {users} = this.formData

    let userFillIn = {
      username: '',
      email_address: '',
      role: 'viewer'
    }
    if (users[userIdx] !== undefined) {
      userFillIn = {...users[userIdx]}
    }
    const inputCssClass = []
    let disabled = false;
    if (this.albumData) {
      inputCssClass.push('disabled')
      disabled = true;
    }
    return (
      <div key={'user-' + userIdx} className="frm-album-user-row">
        <div className="frm-item">
          <label>
            Username<br />
            <input
              type="text"
              onChange={this.handleChange}
              name="username"
              disabled={disabled}
              className={inputCssClass.join(' ')}
              defaultValue={userFillIn['username']}
              userindex={userIdx} />
          </label>
        </div>
        <div className="frm-item">
          <label>Email Address<br />
            <input
              type="email"
              onChange={this.handleChange}
              name="email_address"
              disabled={disabled}
              className={inputCssClass.join(' ')}
              defaultValue={userFillIn['email_address']}
              userindex={userIdx} />
          </label>
        </div>
        <div className="frm-item user-role-container">
          <label>Role<br />
            <select
              name="role"
              onChange={this.handleChange}
              defaultValue={userFillIn['role']}
              disabled={disabled}
              className={inputCssClass.join(' ')}
              userindex={userIdx}>
              <option value="viewer">Viewer</option>
              <option value="sharer">Sharer</option>
              <option value="editor">Editor</option>
              <option value="admin">Administrator</option>
            </select>
          </label>
        </div>
      </div>
    )
  }

  renderSizesRow(sizeIdx) {
    const gallerySizes = this.gallerySizes

    let sizeFillIn = {
      size_name: '',
      width: 500,
      height: 500,
      crop: '',
      watermark: false
    }
    let isGallerySize = false;
    let gallerySizeNames = []
    if (gallerySizes) {
      if (sizeIdx < (gallerySizes.length)) {

        sizeFillIn = gallerySizes[sizeIdx]
        isGallerySize = true;
      }
      gallerySizeNames = gallerySizes.map(size => size['size_name'])
    }
    if (this.formData && !isGallerySize && this.formData['sizes']) {
      const albumSizes = this.formData['sizes'].filter(size => gallerySizeNames.indexOf(size['size_name']) == -1)
      const albumSizeIdx = sizeIdx - gallerySizes.length
      if (albumSizes.length > 0 && albumSizes[albumSizeIdx]) {
        sizeFillIn = albumSizes[albumSizeIdx]
      }
    }
    // console.log(sizeFillIn);

    return (
      <div key={'sizes-' + sizeIdx} className="frm-album-size-row">
        <div className="frm-item">
          <label>
            Size Name<br />
            <input
              type="text"
              onBlur={this.handleChange}
              name="size_name"
              defaultValue={sizeFillIn['size_name']}
              disabled={isGallerySize}
              sizeindex={sizeIdx}
              />
          </label>
        </div>
        <div className="frm-item">
          <label>
            Width<br />
            <input
              type="number"
              min="0"
              max="3000"
              name="width"
              onBlur={this.handleChange}
              defaultValue={sizeFillIn['width']}
              disabled={isGallerySize}
              sizeindex={sizeIdx}
              />
          </label>
        </div>
        <div className="frm-item">
          <label>
            Height<br />
            <input
              type="number"
              min="0"
              max="3000"
              name="height"
              onBlur={this.handleChange}
              defaultValue={sizeFillIn['height']}
              disabled={isGallerySize}
              sizeindex={sizeIdx}
              />
          </label>
        </div>
        <div className="frm-item sizes-crop-container">
          <label>
            Crop<br />
            <select
              name="crop"
              onChange={this.handleChange}
              defaultValue={sizeFillIn['crop']}
              disabled={isGallerySize}
              sizeindex={sizeIdx}
              >
              <option value="">No Crop (size for max width or height)</option>
              <option value="lt">Crop Left-Top</option>
              <option value="lb">Crop Left-Bottom</option>
              <option value="rt">Crop Right-Top</option>
              <option value="rb">Crop Right-Bottom</option>
              <option value="cc">Crop Center-Center</option>
            </select>
          </label>
        </div>
        <div className="frm-item">
          <label>
            Watermark<br />
            <input
              type="checkbox"
              defaultChecked={sizeFillIn['watermark']?true: false}
              name="watermark"
              value="1"
              onChange={this.handleChange}
              disabled={isGallerySize}
              sizeindex={sizeIdx}
            />
          </label>
        </div>
      </div>
    )
  }

  render() {
    const { userCount, albumSizesCount, albumDataLoaded } = this.state
    const {albumPagename} = this.props.params
    const formData = this.formData

    if (albumPagename && albumDataLoaded === false) {
      return <div>Loading Form</div>
    }

    const albumSizesRows = Array(albumSizesCount).fill(0).map((t, userIdx) => {
      return this.renderSizesRow(userIdx)
    })

    const albumSizeOptions = this.formData['sizes']
      .map(size => size['size_name'])
      .map(size_name => {
        return <option key={'size-option-' + size_name} value={size_name}>{size_name}</option>
      })
    albumSizeOptions.unshift(<option key={'size-option-empty'} value="">Select One</option>)
    const downloadAlbumSizes = [...albumSizeOptions]
    downloadAlbumSizes.shift()
    downloadAlbumSizes.unshift(<option key={'size-option-empty'} value="">Use Original Image</option>)
    let btnSubmitText = 'Create Album'
    let formTitle = 'Create New Image Album'
    let disablePageNameField = false
    let returnToAlbum = null
    if (this.albumData) {
      btnSubmitText = 'Save Album'
      formTitle = 'Edit ' + this.albumData['title'] + ' Album'
      disablePageNameField = true
      returnToAlbum = <Link to={'/album/' + this.albumData['pagename']}>&laquo; Return To Album</Link>
    }
    const userSection = this.renderUserSection()

    document.title = formTitle;
    return (
      <form className="frm-edit-album" method="POST">
        {returnToAlbum}
        <h1>{formTitle}</h1>
        <div className="frm-item half">
          <label>Album Title<br />
            <input type="text" name="title" onChange={this.handleChange} defaultValue={formData['title']} required="required" />
          </label>
        </div>
        <div className="frm-item half">
          <label>Album Page Name [Optional]<br />
            <input
              type="text"
              onChange={this.handleChange}
              defaultValue={formData['pagename']}
              name="pagename"
              disabled={disablePageNameField}
              />
          </label>
        </div>
        <div className="frm-item half">
          <label>Author [Optional]<br />
            <input type="text" onChange={this.handleChange}  defaultValue={formData['author']} name="author" />
          </label>
        </div>
        <div className="frm-item half">
          <label>Date Taken [Optional]<br />
            <input type="datetime-local" onChange={this.handleChange} defaultValue={formData['date_taken']} name="date_taken" />
          </label>
        </div>
        <div className="frm-item whole">
          <label>Album Text<br />
            <textarea onChange={this.handleChange} defaultValue={formData['album_text']} name="album_text"></textarea>
          </label>
        </div>
        {userSection}
        <div>
          <h2 className="frm-section-header">Album Sizes:</h2>
          {albumSizesRows}
          <div className="btn-container">
           <input type="button" className="btn btn-submit" onClick={this.addSize} defaultValue="Add Size" />
          </div>
        </div>
        <div>
          <h2 className="frm-section-header">Display Settings:</h2>
          <div className="frm-item half">
            <label>Featured Image Size<br />
              <select onChange={this.handleChange} defaultValue={formData['featured_image_size']} name="featured_image_size" >
                {albumSizeOptions}
              </select>
            </label>
          </div>
          <div className="frm-item half">
            <label>Thumbnail Image Size<br />
              <select onChange={this.handleChange} defaultValue={formData['thumbnail_size']} name="thumbnail_size" >
                {albumSizeOptions}
              </select>
            </label>
          </div>
          <div className="frm-item half">
            <label>Mobile Scroll Image Size<br />
              <select onChange={this.handleChange} defaultValue={formData['filmstrip_size']} name="filmstrip_size" >
                {albumSizeOptions}
              </select>
            </label>
          </div>
          <div className="frm-item half">
            <label>Large Image Size<br />
              <select onChange={this.handleChange} defaultValue={formData['large_size']} name="large_size" >
                {albumSizeOptions}
              </select>
            </label>
          </div>
          <div className="frm-item half">
            <label>Download Size<br />
              <select onChange={this.handleChange} defaultValue={formData['download_size']} name="download_size" >
                {downloadAlbumSizes}
              </select>
            </label>
          </div>
        </div>
        <div className="btn-container">
          <input type="button" className="btn" onClick={this.submitForm} defaultValue={btnSubmitText} />
        </div>
      </form>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    albumKey: state.albumReducer.album_key,
    galleryData: state.galleryReducer.gallery
  }
}
const mapDispatchToProps = (dispatch) => {
 return {
  upsertAlbumData: (album) => dispatch(upsertAlbumData(album))
 }   
}

const EditAlbumFormWithRouter = withRoute(connect(mapStateToProps, mapDispatchToProps)(EditAlbumForm));
export default EditAlbumFormWithRouter
