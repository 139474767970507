import React from "react"
import {Link} from "react-router-dom";

import  { connect } from 'react-redux'
import { addAlbums, addAlbumImageTmpUrlReload } from '../actionTypes/actionTypes'
import config from '../config'

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      albumKey: null,
      loadedAlbumImage: false
    }
    this.albums = []
  }

  loadAlbumFeaturedImage(albumData) {
    const {imageTempUrlCache, albumKey} = this.props
    if (albumData['featured_image_size_filename']) {
      const albumImagePath =  albumData['pagename'] + '/' + albumData['featured_image_size_filename']
      if (!imageTempUrlCache[albumImagePath]) {
        const myHeaders = new Headers({
          Authorization: 'Bearer ' + albumKey,
        })
        const apiurl = config['pyalbum_host'] + '/img/' + albumImagePath
        const response = fetch(apiurl, {
          headers: myHeaders
        })
        .then(response => response.blob())
        .then(imageBlob => {
          const imageObjectUrl = URL.createObjectURL(imageBlob)
          this.props.addAlbumImageTmpUrlReload(albumImagePath, imageObjectUrl)
          // this.setState({
            // loadedAlbumImage: true
          // })
        })
      }
    }
  }

  componentDidMount() {
    const {albumKey} = this.props
    if (this.props.galleryData.length > 0) {
      this.setState({
        isLoaded: true
      });
      this.albums = this.props.galleryData
    }
    else if (albumKey) {
      const myHeaders = new Headers({
        Authorization: 'Bearer ' + albumKey,
      });

      fetch(config['pyalbum_host'] + '/albums', {
        'headers': myHeaders
      }).then((response) => response.json()).then(data =>{
        this.setState({
          isLoaded: true,
          albumKey: albumKey
        });
        this.albums = data
        this.props.addAlbums(data)
      })
    }
  }

  renderAlbumCover(albumData) {
    const {imageTempUrlCache} = this.props
    this.loadAlbumFeaturedImage(albumData)
    const albumPath = '/album/' + albumData['pagename']
    const albumImagePath =  albumData['pagename'] + '/' + albumData['featured_image_size_filename']
    let albumCoverImage = null
    if (imageTempUrlCache[albumImagePath]) {
      albumCoverImage = imageTempUrlCache[albumImagePath]
    }

    return (
      <div key={albumData['albumid']} className="gallery-album-item">
        <div>
          <Link to={albumPath}>
            <img src={albumCoverImage} />
          </Link>
        </div>
        <Link to={albumPath}>
          {albumData['title']}
        </Link><br />
        {albumData['photo_count']} photos
      </div>
    )
  }

  render() {
    const albums = this.albums
    if (albums.length === 0) {
      if (this.state['isLoaded'] || !this.props.albumKey) {
        return <div>No albums Found</div>
      }
      else {
        return <div>Loading albums</div>
      }
    }
    return (
      <div>
        <h1>Albums</h1>
        <div className="gallery-items">
          {albums.map(albumData => this.renderAlbumCover(albumData))}
        </div>
      </div>
    )

  }
}

const mapDispatchToState = (state, ownProps) => {
  return {
    galleryData: state.galleryReducer.gallery,
    albumKey: state.albumReducer.album_key,
    imageTempUrlCache: state.albumReducer.image_temp_url_cache
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlbums: (galleryData) => dispatch(addAlbums(galleryData)),
    addAlbumImageTmpUrlReload: (albumImagePath, filename) => dispatch(addAlbumImageTmpUrlReload(albumImagePath, filename))
  }
}
export default connect(mapDispatchToState, mapDispatchToProps)(Gallery)