const addItem = () => {
  return {
    type: 'add_item'
  }
}

const deleteItem = () => {
  return {
    type: 'delete_item'
  }
}


const addAlbums = (galleryData) => {
    return {
      type: 'add_albums',
      galleryData
    }
}

const upsertAlbumData = (albumData) => {
  return {
    type: 'upsert_album',
    albumData
  }
}

const addAlbumImageTmpUrl = (albumImagePath, imageTmpUrl) => {
  return {
    'type': 'add_image_temp_url',
    albumImagePath,
    imageTmpUrl
  }
}
const addAlbumImageTmpUrlReload = (albumImagePath, imageTmpUrl) => {
  return {
    'type': 'add_image_temp_url_reload',
    albumImagePath,
    imageTmpUrl
  }
}

const setAlbumKey = (albumKey) => {
  return {
    'type': 'set_album_key',
    albumKey
  }
}

export { 
  addItem, 
  deleteItem, 
  addAlbums, 
  upsertAlbumData, 
  addAlbumImageTmpUrl, 
  setAlbumKey, 
  addAlbumImageTmpUrlReload
}