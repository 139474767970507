import { useParams } from 'react-router-dom';

const withRoute = WrappedPage => props => {
  const params = useParams();
  
  return (
    <WrappedPage
      {...props}
      {...{ params}}
    />
  )
  
}

export {withRoute}