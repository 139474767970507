import React from "react"
import {Link} from "react-router-dom";

const Header = (props) => {
  if (!props['user_type'] || props.user_type == 'album_user') {
    return <></>
  }
  const superAdminLinks = []
  if (props.user_type == 'superadmin') {
    superAdminLinks.push(<Link key={'album/create'} to="/album/create">Create Album</Link>)
  }
  
  return (
    <header>
      <nav className="main-navigation">
        <Link to="/">Albums</Link>
        {superAdminLinks}
      </nav>
    </header>)
}
export default Header
