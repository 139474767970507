import { Component, createRef } from 'react'
import { connect } from 'react-redux'

import config from '../config'
import { addAlbumImageTmpUrl } from '../actionTypes/actionTypes'

class AlbumSlideShowCarouselImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      thumbnailImageSrc: null
    }
    this.hasBeenRequested = false;
    this.carouselImageContainer = createRef()
  }

  loadThumbnailImage(loadImage) {
    const {albumData, imageData, imageTempUrlCache, albumKey} = this.props

    let thumbnailImageSrc = null;
    let imagePath = null
    if (albumData['filmstrip_size'] && imageData['sizes'][albumData['filmstrip_size']]) {
      imagePath = albumData['pagename'] + '/' + imageData['sizes'][albumData['filmstrip_size']]['size_filename']

      if (imageTempUrlCache[imagePath]) {
        thumbnailImageSrc = imageTempUrlCache[imagePath]
      }
    }
    if (thumbnailImageSrc == null && albumData['thumbnail_size'] && imageData['sizes'][albumData['thumbnail_size']]) {
      const thumbnailImagePath = albumData['pagename'] + '/' + imageData['sizes'][albumData['thumbnail_size']]['size_filename']
      if (imagePath === null) {
        imagePath = thumbnailImagePath;
      }
      if (imageTempUrlCache[thumbnailImagePath]) {
        thumbnailImageSrc = imageTempUrlCache[thumbnailImagePath]
      }
    }
    // console.log(imagePath)
    // console.log(thumbnailImageSrc)
    if (thumbnailImageSrc) {
      this.setState({
        thumbnailImageSrc: thumbnailImageSrc
      })
    }
    else {

        // if (this['carouselImages'][imageData['filename']]
        // && this['carouselImages'][imageData['filename']].offsetLeft < maxInnerContainerScroll) {
          if (loadImage && this.hasBeenRequested === false) {
            this.hasBeenRequested = true;
            console.log('requesting ' + imageData['filename'])
            const myHeaders = new Headers({
              Authorization: 'Bearer ' + albumKey,
            })
            const apiurl = config['pyalbum_host'] + '/img/' + imagePath
            const response = fetch(apiurl, {
              headers: myHeaders
            })
              .then(response => response.blob())
              .then(imageBlob => {
                const imageObjectUrl = URL.createObjectURL(imageBlob)
                this.props.addAlbumImageTmpUrl(imagePath, imageObjectUrl)
                this.setState({
                  thumbnailImageSrc: imageObjectUrl
                })
              })
          // console.log('-----')
          // console.log(imagePath)
          // this.loadThumbnailImage(imagePath)
        }
      // if (imageTempUrlCache[imagePath]) {
        // thumbnailImageSrc = imageTempUrlCache[imagePath]
      // }
      // console.log(thumbnailImageSrc)
      // console.log(this['carouselImages'][imageData['filename']].offsetLeft)
      // console.log(this['carouselImages'][imageData['filename']].offsetLeft)
    }

    // const imageBlob = await response.blob()
    // return imageObjectUrl;
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props['loadImageState']
        && (this.props['loadImageState'] != prevProps['loadImageState'] ||
            (prevState['thumbnailImageSrc'] === null
             && (this.carouselImageContainer.current.offsetLeft - 500) < this.props['maxInnerContainerScroll'] && this.props['maxInnerContainerScroll'] > 0))
        ) {
      // console.log('got here')
      // console.log(this.carouselImageContainer.current.offsetLeft)
      // console.log(this.props['maxInnerContainerScroll'])

      this.loadThumbnailImage((this.carouselImageContainer.current.offsetLeft-500) < this.props['maxInnerContainerScroll'] && this.props['maxInnerContainerScroll'] > 0)
    }
    // console.log('iscurrent')
    // console.log(prevProps['isCurrentImage'])
    // console.log(this.props['isCurrentImage'])

    if ((prevProps['isCurrentImage'] !== this.props['isCurrentImage'] || prevState['thumbnailImageSrc'] === null) && this.props['isCurrentImage']) {
      window.setTimeout(this.scrollCarouselTo.bind(this), 100)
    }
  }

  scrollCarouselTo(thumbnailContainer) {
    if (this.carouselImageContainer && this.carouselImageContainer.current) {
      const parentEle = this.carouselImageContainer.current.parentElement
      const outerContainerEleWidth = parentEle.parentElement.clientWidth
      let scrollToX = this.carouselImageContainer.current.offsetLeft - (outerContainerEleWidth / 2) + (this.carouselImageContainer.current.clientWidth / 2)
      // + (parentEleWidth / 2) - (thumbnailContainer.clientWidth / 2)
      if (scrollToX < 0) {
        scrollToX = 0;
      }
      parentEle.scrollTo(scrollToX, 0)
    }
    // console.log('scrollToX')
    // console.log(scrollToX)

  }

  shouldComponentUpdate(nextProps, nextState) {
    // Rendering the component only if
    // passed props value is changed
    // console.log('-test' + this.props['imageData']['title'])
    // console.log(this.props['loadImageState'])
    // console.log(nextProps['loadImageState'])
    // console.log((nextProps['loadImageState'] && this.props['loadImageState'] === false))
    // console.log(nextProps['isSelected'] !== this.props['isSelected'])
    // console.log(nextState['thumbnailImageSrc'])
    // console.log(this.state['thumbnailImageSrc'])
    // console.log(nextProps['maxInnerContainerScroll'])
      // console.log('-----')
    // console.log(this.carouselImageContainer)
    // if (this.carouselImageContainer.current) {
      // console.log(this.carouselImageContainer.current.offsetLeft)
      // console.log(this.carouselImageContainer.current.offsetLeft < nextProps['maxInnerContainerScroll'])
      // console.log(nextProps['maxInnerContainerScroll'] != this.props['maxInnerContainerScroll']
            // && this.carouselImageContainer.current.offsetLeft < nextProps['maxInnerContainerScroll'])
    // }

    if ((nextProps['loadImageState'] && this.props['loadImageState'] === false)
        || nextProps['isSelected'] !== this.props['isSelected']
        || (nextProps['isCurrentImage'] !== this.props['isCurrentImage'])
        || nextState['thumbnailImageSrc'] != this.state['thumbnailImageSrc']
        || (nextProps['maxInnerContainerScroll'] != this.props['maxInnerContainerScroll']
            && (this.carouselImageContainer.current.offsetLeft - 500) < nextProps['maxInnerContainerScroll'] 
            && this.props['maxInnerContainerScroll'] > 0)
        ) {
      // console.log(this.carouselImageContainer.current.offsetLeft)
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    const {loadImageState} = this.props
    // if (this.carouselImageContainer.current) {
      // console.log(this.carouselImageContainer.current.offsetTop);

    // }
    if (loadImageState) {
      this.loadThumbnailImage()
    }
  }

  render() {
    // console.log('got redune')
    const {isCurrentImage, albumData, imageData} = this.props
    const {thumbnailImageSrc} = this.state

    const classNames = ['slideshow-carousel-thumbnail']
    if (isCurrentImage) {
      classNames.push('carousel-active-img')
    }
    if (this.props.isSelected) {
      classNames.push('selected-image')
    }

    return (<div
        ref={this.carouselImageContainer}
        className={classNames.join(' ')}>
        <img
          src={thumbnailImageSrc}
          width={imageData['sizes']['thumbnail']['width']}
          height={imageData['sizes']['thumbnail']['height']}
          onClick={(e) => this.props.displayLargerImage(e, imageData)}
          onLoad={this.props.loadNextCarouselImage}
        />
        <div>{imageData['title']}</div>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    albumKey: state.albumReducer.album_key,
    imageTempUrlCache: state.albumReducer.image_temp_url_cache
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addAlbumImageTmpUrl: (albumImagePath, filename) => dispatch(addAlbumImageTmpUrl(albumImagePath, filename)),
    addAlbumImageTmpUrlReload: (albumImagePath, filename) => dispatch(addAlbumImageTmpUrlReload(albumImagePath, filename))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumSlideShowCarouselImage)