import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux'
import {BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { createRoot } from 'react-dom/client';
import { Provider, useSelector, useDispatch } from 'react-redux'
import { createStore } from 'redux';
import combindedReducer from './reducers'
import { setAlbumKey } from './actionTypes/actionTypes'

import Header from './components/Header'
import Gallery from './pages/gallery'
import AlbumWithRouter from './pages/album'
import EditAlbumForm from './pages/editAlbumForm'
import config from './config'

import {isMobile} from './utilityFunctions'
import 'normalize.css';
import './styles.css'

// const router = createBrowserRouter([
// {path: "/", element: <div>help me</div>}

// ])
const store = createStore(combindedReducer)
store.subscribe(() => {
  const storeState = store.getState()
  const sessionAlbumKey = sessionStorage.getItem('albumkey')
  if (storeState.albumReducer.album_key !== sessionAlbumKey) {
    sessionStorage.setItem('albumkey', storeState.albumReducer.album_key)
  }
})
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasQueryString: false,
      user_type: null,
      screenType: isMobile() ? 'mobile': 'desktop'
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    let albumKey = urlSearchParams.get('albumkey')
    if (albumKey) {
      this.state['hasQueryString'] = true
    }
    else {
      albumKey = sessionStorage.getItem('albumkey')
    }

    if (albumKey) {
      const myHeaders = new Headers({
        'Authorization': 'Bearer ' + albumKey,
      })
      fetch(config['pyalbum_host'] + '/user', {
        headers: myHeaders
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          user_type: data.user_type
        })
      })
      this.props.setAlbumKey(albumKey)
    }
  }

  updateViewScreen() {
    this.state['screenType'] = isMobile() ? 'mobile': 'desktop'
    // this.setState({
      // screenType:
    // })
    const setViewClass = this.state.screenType + '-view'
    if (setViewClass == 'mobile-view' && !document.body.classList.contains('mobile-view')) {
      document.body.classList.remove('desktop-view')
      document.body.classList.add('mobile-view')
    }
    else if (setViewClass == 'desktop-view' && !document.body.classList.contains('desktop-view')) {
      document.body.classList.remove('mobile-view')
      document.body.classList.add('desktop-view')
    }
  }

  componentDidMount() {
    const {hasQueryString } = this.state;
    if (hasQueryString) {
      history.replaceState(null, '', location.pathname)
    }
    window.addEventListener('resize', this.updateViewScreen.bind(this))

  }

  render() {
    const {user_type, screenType} = this.state
    document.body.className = screenType + '-view'
    return (<BrowserRouter>

      <Header user_type={user_type} />
      <main className={'main-content'}>
        <Routes>
          <Route path="/">
            <Route index element={<Gallery />} />
            // <Route path="gallery/*" element={<Gallery />} />
            <Route path="album/create" element={<EditAlbumForm />} />
            <Route path="album/:albumPagename/">
              <Route index element={<AlbumWithRouter screenType />} />
              <Route path="edit" element={<EditAlbumForm />} />
            </Route>
            /* <Route path="album/:albumPagename/edit" element={<AlbumWithRouter />} />
            // <Route path="album/:albumPagename" element={<EditAlbumForm />} />*/
          </Route>
        </Routes>
      </main>
      <footer></footer>
    </BrowserRouter>)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAlbumKey: albumKey => dispatch(setAlbumKey(albumKey))
  }
}

const TheApp = connect(null, mapDispatchToProps)(App)
// const App = () => {

  // return (

  // );
// }
createRoot(document.getElementById('app')).render(
  <Provider store={store}>
    <TheApp />
  </Provider>
)
